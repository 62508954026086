import "$styles/index.css"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

// console.info("Bridgetown is loaded!")

document.addEventListener('DOMContentLoaded', function () {
     var e = "cHVibGljQGZhbHNldHJ1ZS5pbwo=";
     const ee = ['em_s'];
     var f = "";
     for (i = 0; i<= ee.length-1;i++) {
         v = ee[i];
         els = document.getElementsByClassName(v);
         for (y = 0; y <= els.length - 1; y++) {
             els[y].setAttribute("href", "mailto:".concat(atob(e)));
         }
     }
 });
